<template>
  <div class="main-content">
    <div class="container-fluid main-container">
      <div class="row">
        <div class="col-md-8">
          <before-event v-if="event.status == 'upcoming'" />
          <after-event v-if="event.status == 'completed'" />
          <live-player v-if="event.status == 'live'" />
          <vod v-if="event.status == 'vod'" />
          <player-bottom-bar />
          <question style="margin-top: 16px" v-if="event.qnaEnabled" />
        </div>
        <div class="col-md-4">
          <div class="card stand-logo-sec">
            <div class="card-body">
              <h5 class="card-title">Stand Alanı</h5>
              <!--
              <router-link
                :to="'/' + event._id + '/booth/alexion'"
                class="stand-logo"
              >
                <img
                  class="img-fluid"
                  src="@/assets/Alexion-Kongre-Banner+2.jpeg"
                  alt="Stand Banner"
                />
              </router-link>
              <router-link :to="'/' + event._id + '/booth/astrazeneca'">
                <img
                  class="img-fluid"
                  src="@/assets/beloc_banner_2022-01.jpg"
                  alt="Stand Banner"
                />
              </router-link>
              <router-link :to="'/' + event._id + '/booth/drogsan'">
                <img
                  class="img-fluid"
                  src="@/assets/Norm Asidoz - Stand Alanı Banner.jpg"
                  alt="Stand Banner"
                />
              </router-link>
              -->
              <router-link
                :to="'/' + event._id + '/booth/alexion'"
                class="stand-logo"
              >
                <img
                  class="img-fluid"
                  src="@/assets/alexion_logo.png"
                  alt="Stand Banner"
                />
              </router-link>
              <router-link
                :to="'/' + event._id + '/booth/astrazeneca'"
                class="stand-logo"
              >
                <img
                  class="img-fluid"
                  src="@/assets/azc_logo.png"
                  alt="Stand Banner"
                />
              </router-link>
              <router-link
                :to="'/' + event._id + '/booth/drogsan'"
                class="stand-logo"
              >
                <img
                  class="img-fluid"
                  src="@/assets/drogsan_logo.png"
                  alt="Stand Banner"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="lang-select-wrapper">
        <b-form-group
          :label="$t('page_language')"
          label-for="page-lang"
          label-size="sm"
        >
          <b-form-select
            id="page-lang"
            v-model="selectedLang"
            @change="updateSelectedLang()"
          >
            <b-form-select-option value="en">English</b-form-select-option>
            <b-form-select-option value="de">Deutsche</b-form-select-option>
            <b-form-select-option value="es">Espanol</b-form-select-option>
            <b-form-select-option value="tr">Turkish</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import LivePlayer from '@/components/LivePlayer.vue';
import BeforeEvent from '@/components/BeforeEvent.vue';
import AfterEvent from '@/components/AfterEvent.vue';
import PlayerBottomBar from '@/components/PlayerBottomBar.vue';
import Question from '@/components/Question.vue';
import Vod from '@/components/Vod.vue';
import { mapGetters } from 'vuex';
import { loadLanguageAsync } from '@/utils/i18n-setup';
import _ from 'lodash';

export default {
  data() {
    return {
      bgImgStyle: {},
      selectedLang: 'en',
    };
  },
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
  },
  components: {
    LivePlayer,
    Question,
    PlayerBottomBar,
    BeforeEvent,
    AfterEvent,
    Vod,
  },
  methods: {
    updateSelectedLang() {
      loadLanguageAsync(this.selectedLang);
      this.$moment.locale(this.selectedLang);
    },
  },
  async mounted() {
    if (_.has(this.event, 'pageSettings.bgImgUrl')) {
      this.bgImgStyle = {
        backgroundImage: `url('${this.event.pageSettings.bgImgUrl}')`,
        backgroundPositionX: this.event.pageSettings.bgImgPosition,
      };
    }

    // init selected language
    loadLanguageAsync(this.event.interfaceLang);
    this.selectedLang = this.event.interfaceLang;

    this.$moment.locale(this.selectedLang);

    return true;
  },
};
</script>

<style lang="scss">
.main-content {
  padding-top: 96px;
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .stand-logo-sec {
    border: none;
    .stand-logo {
      display: block;
      margin-bottom: 16px;
      max-width: 200px;
      width: 100%;
    }
    @media only screen and (max-width: 480px) {
      margin-top: 32px;
    }
  }
}
</style>
