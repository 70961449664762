<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <router-link :to="'/' + event._id" class="nav-item nav-link btn btn-danger"
      >Canlı Yayına Dön</router-link
    >
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      streamList: 'stream/list',
      user: 'user/current',
      event: 'event/current',
      stream: 'stream/current',
      appSettings: 'appsettings/current',
    }),
  },
};
</script>

<style>
.navbar-light {
  background-color: #fff;
  display: flex;
  justify-content: flex-end !important;
}
</style>
