//Alexion booth

<template>
  <div class="stand">
    <Header />
    <div class="container wide mt-4">
      <div class="box">
        <div class="row">
          <div class="col-md-12">
            <img
              class="img-fluid"
              src="@/assets/Norm Asidoz - Stand Alanı Banner.jpg"
              alt="Stand Banner"
            />
          </div>
        </div>
        <div class="row p-4">
          <div class="col-md-7">
            <img
              class="img-fluid"
              src="@/assets/dropoetin_video_.jpg"
              alt="Stand Banner"
            />

            <!--
            <p class="mt-4">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.<br />
              Pariatur vero sequi esse ad provident deleniti in eum quis,
              aperiam quod quia.
            </p>
            -->
          </div>
          <div class="col-md-5 stand-buttons">
            <div>
              <a href="/img/Dropoetin.pdf" target="_blank">
                <span>Dropoetin Ürün Bilgisi</span>
              </a>
              <a href="/img/Norm-Asidoz.pdf" target="_blank">
                <span>Norm_Asidoz Ürün Bilgisi</span>
              </a>
              <a
                href="https://livecast-tr.zoom.us/j/84611534807"
                target="_blank"
              >
                <span>Canlı Görüşme</span>
              </a>
              <a href="https://drogsan.com.tr/" target="_blank">
                <span>İletişim</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  data() {
    return {
      booth: null,
      isReady: false,
      publicPath: process.env.BASE_URL,
    };
  },
  components: {
    Header,
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss">
.stand {
  padding-top: 0px;
  display: flex;
  flex-direction: column;

  .box {
    padding-top: 15px;
  }
  .container {
    border-radius: 8px;
    background-color: #fff;
    height: fit-content;
  }
}
/*Stand Buttons*/
.stand-buttons {
  div {
    background: #f5f5f5;
    padding: 2rem;
    height: 100%;

    a {
      display: flex;
      align-items: center;
      color: black;
      font-weight: 600;
      text-decoration: none;
      background-color: #fff;
      border-radius: 3px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
      padding: 1.125rem;
      margin-bottom: 1rem;
      border: 1px solid transparent;
      transition: border-color 0.4s ease;
      line-height: 1.2;

      span {
        small {
          display: block;
          font-size: 0.875rem;
          color: rgba(0, 0, 0, 0.4);
        }
      }

      img {
        width: 2rem;
        margin-right: 0.5rem;
      }

      &:hover {
        span {
          color: #000;
        }

        border-color: #ccc;
      }
    }
  }
}

/*Stand Buttons Responsive*/
@media only screen and (max-width: 480px) {
  .stand-buttons div {
    padding: 1rem;
  }
}

/*End Stand Buttons Responsive*/
</style>
