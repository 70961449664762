import Vue from 'vue';
import VueRouter from 'vue-router';

import Alexion from '@/views/booth/Alexion.vue';
import Astrazeneca from '@/views/booth/Astrazeneca.vue';
import Drogsan from '@/views/booth/Drogsan.vue';
import Live from '@/views/Live.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:slug',
    name: 'Live',
    component: Live,
  },
  {
    path: '/:slug/booth/alexion',
    name: 'Alexion',
    component: Alexion,
  },
  {
    path: '/:slug/booth/astrazeneca',
    name: 'Astrazeneca',
    component: Astrazeneca,
  },
  {
    path: '/:slug/booth/drogsan',
    name: 'Drogsan',
    component: Drogsan,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
